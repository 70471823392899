import * as React from "react";
import { EventCategory } from "../../../utils/analytics";
import PillButton from "../../ui-library/buttons/pill-button";
import { Card } from "../../ui-library/cards/card";
import Link from "../../ui-library/link/link";
import styles from "./cookie-policy.styles";

type Acceptance = "agree" | "disagree" | "reading" | "unset";

const localStorageKey = "cookiePolicy";

const CookiePolicy: React.FC = () => {
  const [acceptance, setAcceptance] = React.useState<Acceptance>("reading");

  const handleSetAcceptance = (newAcceptance: Acceptance) => {
    setAcceptance(newAcceptance);
    localStorage.setItem(localStorageKey, newAcceptance);
    // @ts-ignore dataLayer
    if (dataLayer) {
      // @ts-ignore dataLayer
      dataLayer.push({
        cookiePolicy: newAcceptance,
      });
    }
  };

  React.useEffect(() => {
    const storedAcceptance =
      (localStorage.getItem(localStorageKey) as Acceptance) || null;
    handleSetAcceptance(storedAcceptance || "unset");
  }, []);

  const eventCategory = EventCategory.Site_CookiePolicy;

  if (acceptance !== "unset") {
    return null;
  }

  return (
    <Card pop={true} css={styles.root}>
      <div css={styles.content}>
        <div css={styles.headingContainer}>
          <Link
            css={styles.heading}
            to="/privacy-policy/"
            analytics={{
              category: eventCategory,
              action: "Click - Privacy policy",
              label: "We use cookies",
            }}
          >
            We use cookies
          </Link>
          <div css={styles.detail}>Are you ok with that?</div>
        </div>

        <div css={styles.buttonContainer}>
          <PillButton
            onClick={() => handleSetAcceptance("agree")}
            name="yes"
            color="blue"
            size="small"
            css={styles.button}
            eventCategory={eventCategory}
          >
            Yes
          </PillButton>
          <PillButton
            onClick={() => handleSetAcceptance("disagree")}
            name="no"
            outline={true}
            size="small"
            css={[styles.button, styles.noButton]}
            eventCategory={eventCategory}
          >
            No
          </PillButton>
        </div>
      </div>
    </Card>
  );
};

export default CookiePolicy;
