import { CSSObject } from "@emotion/react";
import { Colors } from "../../ui-library/design-tokens/colors";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  backgroundColor: Colors.Black,
};

const content: CSSObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: grid(5),

  [Device.DesktopTablet]: {
    height: grid(7),
  },
};

const logoLink: CSSObject = {
  display: "flex",
  alignItems: "center",
  height: "100%",
};

const nav: CSSObject = {
  display: "flex",
  alignItems: "center",
  height: "100%",
};

const styles = {
  root,
  content,
  logoLink,
  nav,
};

export default styles;
