import { CSSObject } from "@emotion/react";
import { lighten } from "polished";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";

const root: CSSObject = {
  position: "relative",
};

const button: CSSObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(7),
  height: grid(3),
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.White,
  backgroundColor: Colors.Blue,
  borderRadius: grid(3),
  transition: "all 0.2s ease",

  [Device.DesktopTablet]: {
    width: grid(8),
    height: grid(4),

    ":hover": {
      backgroundColor: lighten(0.05, Colors.Blue),
    },
  },
};

const dropdownContainer: CSSObject = {
  position: "absolute",
  top: grid(4),
  right: 0,
  width: "calc(100vw - 40px)",
  zIndex: 10,

  [Device.DesktopTablet]: {
    top: grid(7.5),
    width: grid(29.5),
  },
};

const row: CSSObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: grid(4),
  paddingLeft: grid(1.5),
  paddingRight: grid(1.5),
};

const header: CSSObject = {
  fontSize: 12,
  color: Colors.MidGrey,
  fontWeight: Fonts.Weight.SemiBold,
};

const item: CSSObject = {
  position: "relative",
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,

  ":hover": {
    backgroundColor: Colors.LightestGrey,
  },
};

const itemActive: CSSObject = {
  fontWeight: Fonts.Weight.Bold,

  "::before": {
    content: `''`,
    position: "absolute",
    top: 12,
    left: 0,
    display: "block",
    width: 4,
    height: 16,
    backgroundColor: Colors.Green,
  },
};

const symbol: CSSObject = {
  fontSize: 12,
  color: Colors.MidGrey,
};

const styles = {
  root,
  button,
  dropdownContainer,
  row,
  header,
  item,
  itemActive,
  symbol,
};

export default styles;
