import { CSSObject } from "@emotion/react";
import { LayoutSize } from "../design-tokens/layout-size";
import { grid } from "../design-tokens/grid";
import { Device } from "../design-tokens/media-queries";

const root: CSSObject = {
  position: "relative",
  maxWidth: LayoutSize.Container,
  marginLeft: "auto",
  marginRight: "auto",
};

const margin: CSSObject = {
  [Device.TabletMobile]: {
    marginLeft: grid(2),
    marginRight: grid(2),
  },
};

const styles = {
  root,
  margin,
};

export default styles;
