export enum Colors {
  White = "#ffffff",
  Black = "#242732",
  DarkGrey = "#383F58",
  LightestGrey = "#F8F9FC",
  LightGrey = "#F3F5F9",
  BorderGrey = "#EAECF3",
  MidGrey = "#566982",

  ShadowBlue = "#435686",

  Blue = "#2550BE",
  BlueBerry = "#3E50B4",
  Red = "#E44343",
  Orange = "#FE6B18",
  Green = "#299C56",
  Purple = "#9B51E0",
}
