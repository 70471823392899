import * as React from "react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import { EventAttributes, getEventAttributes } from "../../../utils/analytics";

// TODO: do it properly later
const isExternalUrl = (url: string) => {
  return url.includes("http");
};

interface LinkProps extends GatsbyLinkProps<any> {
  analytics: EventAttributes;
}

const Link: React.FC<LinkProps> = ({
  to,
  analytics,
  target,
  ref: _ref,
  ...props
}) => {
  const isExternal = isExternalUrl(to);

  return !isExternal ? (
    <GatsbyLink to={to} {...props} {...getEventAttributes(analytics)} />
  ) : (
    <a
      href={to}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}
      {...props}
      {...getEventAttributes(analytics)}
    />
  );
};

export default Link;
