export enum EventCategory {
  Site_Header = "Site - Header",
  Site_Footer = "Site - Footer",
  Site_Navigate = "Site - Navigate",
  Site_Search = "Site - Search",
  Site_CookiePolicy = "Site - Cookie policy",

  Home = "Home", // TODO
  CoinList = "CoinList", // TODO
  CoinPage = "CoinPage", // TODO
  ExchangeList = "ExchangeList", // TODO
  ExchangePage = "ExchangePage", // TODO
  CategoriesPage = "CategoriesPage",

  FeaturedCoins = "Featured coins",

  NotFoundPage = "NotFoundPage",
}

export interface EventAttributes {
  category: EventCategory;
  action: string;
  label: string;
  value?: string;
}

interface EventData extends EventAttributes {
  event: "ActionableClick" | "Impression";
}

export const getEventAttributes = ({
  category,
  action,
  label,
  value,
}: EventAttributes) => {
  const eventAttrs: { [key: string]: string } = {
    "data-event-category": category,
    "data-event-action": action,
    "data-event-label": label,
  };

  if (value) {
    eventAttrs["data-event-value"] = value;
  }

  return eventAttrs;
};

const pushEventDataLayer = ({
  event,
  category,
  action,
  label,
  value,
}: EventData) => {
  const eventDataLayer: { [key: string]: string } = {
    event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  };

  if (value) {
    eventDataLayer.eventValue = value;
  }

  // @ts-ignore dataLayer
  if (!!window && !!dataLayer) {
    // @ts-ignore dataLayer
    dataLayer.push(eventDataLayer);
  }
};

export const pushActionableClickEvent = (attrs: EventAttributes) => {
  pushEventDataLayer({
    event: "ActionableClick",
    ...attrs,
  });
};

export const pushInpressionEvent = (attrs: EventAttributes) => {
  pushEventDataLayer({
    event: "Impression",
    ...attrs,
  });
};
