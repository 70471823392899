import { CSSObject } from "@emotion/react";
import { Colors } from "../../ui-library/design-tokens/colors";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  backgroundColor: Colors.Black,
  paddingTop: grid(4),
  paddingBottom: grid(5),

  [Device.Desktop]: {
    paddingTop: grid(4.5),
    paddingBottom: grid(4.5),
  },
};

const container: CSSObject = {
  display: "flex",
  alignItems: "center",

  [Device.Desktop]: {
    justifyContent: "space-between",
  },

  [Device.TabletMobile]: {
    flexDirection: "column",
  },
};

const slogan: CSSObject = {
  color: Colors.White,

  [Device.Desktop]: {
    marginRight: grid(3),
  },

  [Device.TabletMobile]: {
    textAlign: "center",
    marginBottom: grid(4),
  },
};

const linksContainer: CSSObject = {
  [Device.Desktop]: {
    display: "flex",
    alignItems: "center",
  },

  [Device.TabletMobile]: {
    marginBottom: grid(3),
  },
};

const link: CSSObject = {
  color: Colors.White,
  whiteSpace: "nowrap",

  "&::after": {
    content: `"|"`,
    color: Colors.MidGrey,
    marginLeft: grid(1.5),
    marginRight: grid(1.5),

    [Device.MobilePortXs]: {
      marginLeft: 8,
      marginRight: 8,
    },
  },

  "&:last-of-type::after": {
    display: "none",
  },

  [Device.TabletMobile]: {
    fontSize: 14,
  },
};

const twitterDesktop: CSSObject = {
  marginLeft: grid(2.5),
  marginRight: grid(2),
  [Device.TabletMobile]: {
    display: "none",
  },
};

const twitterMobile: CSSObject = {
  marginBottom: grid(3),

  [Device.Desktop]: {
    display: "none",
  },
};

const tradeMark: CSSObject = {
  color: Colors.White,
  whiteSpace: "nowrap",

  [Device.TabletMobile]: {
    display: "none",
  },
};

const tradeMarkMobile: CSSObject = {
  fontSize: 14,
  color: Colors.White,

  [Device.Desktop]: {
    display: "none",
  },
};

const sitemapLink: CSSObject = {
  color: "inherit",
  cursor: "inherit",

  ":hover": {
    textDecoration: "none",
  },
};

const styles = {
  root,
  container,
  slogan,
  linksContainer,
  link,
  twitterDesktop,
  twitterMobile,
  tradeMark,
  tradeMarkMobile,
  sitemapLink,
};

export default styles;
