import * as React from "react";
import Container from "../../ui-library/container/container";
import Image from "../../ui-library/image/image";
import logo from "../../../images/logo-white.svg";
import Navigation, { NavTab } from "../navigation/navigation";
import CurrencySelector from "../currency-selector/currency-selector";
import MarginBox from "../../ui-library/margin-box/margin-box";
import { grid } from "../../ui-library/design-tokens/grid";
import Link from "../../ui-library/link/link";
import { EventCategory } from "../../../utils/analytics";
import styles from "./header.styles";

interface HeaderProps {
  activeTab?: NavTab;
}

const Header: React.FC<HeaderProps> = ({ activeTab }) => {
  return (
    <>
      <header css={styles.root}>
        <Container css={styles.content}>
          <Link
            to="/"
            css={styles.logoLink}
            analytics={{
              category: EventCategory.Site_Header,
              action: "Click - Logo",
              label: "COINOMICS",
            }}
          >
            <Image src={logo} alt="COINOMICS" turnOffLazyLoading={true} />
          </Link>

          <div css={styles.nav}>
            <Navigation activeTab={activeTab} device="desktop" />

            <MarginBox margin={{ left: grid(2) }}>
              <CurrencySelector />
            </MarginBox>
          </div>
        </Container>
      </header>

      <Navigation activeTab={activeTab} device="mobile" />
    </>
  );
};

export default Header;
