import { lighten } from "polished";
import { CSSObject } from "@emotion/react";
import { Device } from "../../ui-library/design-tokens/media-queries";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";

const root: CSSObject = {
  display: "flex",
  backgroundColor: Colors.DarkGrey,

  [Device.DesktopTablet]: {
    height: "100%",
    backgroundColor: Colors.Black,
  },
};

const hiddenMobile: CSSObject = {
  [Device.Mobile]: {
    display: "none",
  },
};

const hiddenDesktop: CSSObject = {
  [Device.DesktopTablet]: {
    display: "none",
  },
};

const item: CSSObject = {
  flex: 1,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  height: 42,
  fontSize: 12,
  lineHeight: grid(2),
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.White,
  paddingTop: grid(1),
  whiteSpace: "nowrap",

  ":hover": {
    backgroundColor: lighten(0.1, Colors.DarkGrey),
  },

  [Device.DesktopTablet]: {
    minWidth: grid(15),
    height: "100%",
    fontSize: 14,
    paddingTop: grid(2.5),

    ":hover": {
      backgroundColor: lighten(0.1, Colors.Black),
    },
  },

  [Device.Tablet]: {
    minWidth: grid(11),
  },
};

const itemActive: CSSObject = {
  fontWeight: Fonts.Weight.Bold,
  backgroundColor: Colors.Blue,

  ":hover": {
    backgroundColor: lighten(0.1, Colors.Blue),
  },
};

const styles = {
  root,
  hiddenMobile,
  hiddenDesktop,
  item,
  itemActive,
};

export default styles;
