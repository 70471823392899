import { CSSObject } from "@emotion/react";
import { BoxShadow } from "../design-tokens/box-shadow";
import { Colors } from "../design-tokens/colors";
import { Fonts } from "../design-tokens/fonts";
import { grid } from "../design-tokens/grid";

const root: CSSObject = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "100%",
  height: grid(4),
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  paddingLeft: grid(1.5),
  paddingRight: grid(1.5),
  borderRadius: grid(4),
  boxShadow: BoxShadow.Default,
  transition: "all 0.2s ease",
  overflow: "hidden",

  ":hover": {
    boxShadow: BoxShadow.Hover,
    transform: "translateY(-2px)",
  },

  ":active": {
    transform: "translateY(2px)",
  },
};

const white: CSSObject = {
  color: Colors.Black,
  backgroundColor: Colors.White,
};

const blue: CSSObject = {
  color: Colors.White,
  backgroundColor: Colors.Blue,
};

const small: CSSObject = {
  height: grid(3),
  fontSize: 12,
};

const border: CSSObject = {
  border: `1px solid ${Colors.Blue}`,
};

const label: CSSObject = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const styles = {
  root,
  white,
  blue,
  small,
  border,
  label,
};

export default styles;
