import { CSSObject } from "@emotion/react";
import { Keyframes } from "../../ui-library/design-tokens/animation";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  position: "fixed",
  right: grid(5),
  bottom: grid(5),
  overflow: "hidden",
  borderRadius: "6px",
  zIndex: 100,
  animation: `${Keyframes.FadeIn} 0.2s linear`,

  [Device.Mobile]: {
    left: grid(2),
    right: grid(2),
    bottom: grid(2),
  },

  [Device.MobilePortXs]: {
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const content: CSSObject = {
  display: "flex",
  alignItems: "center",
  paddingLeft: grid(1.5),
  paddingRight: grid(2),
  borderLeft: `6px solid ${Colors.Blue}`,
};

const headingContainer: CSSObject = {
  paddingTop: grid(1),
  paddingBottom: grid(1.5),
};

const heading: CSSObject = {
  display: "block",
  fontSize: 14,
  fontWeight: Fonts.Weight.Bold,
  color: Colors.Black,
  marginBottom: grid(0.5),

  ":hover": {
    color: Colors.Blue,
  },
};

const detail: CSSObject = {
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.MidGrey,
};

const buttonContainer: CSSObject = {
  marginLeft: grid(2),
};

const button: CSSObject = {
  width: grid(7),
  fontWeight: Fonts.Weight.Bold,

  [Device.MobilePortXs]: {
    width: grid(6),
  },
};

const noButton: CSSObject = {
  color: Colors.Blue,
  marginLeft: grid(1),
};

const styles = {
  root,
  content,
  heading,
  headingContainer,
  detail,
  buttonContainer,
  button,
  noButton,
};

export default styles;
