import * as React from "react";
import AnimateHeight from "react-animate-height";
import { SiteContext } from "../../../site-context/site-context";
import Actionable from "../../ui-library/actionable/actionable";
import { EventCategory } from "../../../utils/analytics";
import {
  SUPPORTED_CURRENCIES,
  CURRENCY_NAME,
} from "../../../constants/currencies";
import { CurrencySymbol } from "../../../../types/currency";
import { setCurrency } from "../../../site-context/store/currency";
import Arrow from "../../ui-library/arrow/arrow";
import { Card } from "../../ui-library/cards/card";
import { grid } from "../../ui-library/design-tokens/grid";
import { useClickOut } from "../../../hooks/use-click-out";
import styles from "./currency-selector.styles";

const CurrencySelector: React.FC = () => {
  const {
    currency: [currency, dispatch],
    usdExchangeRate,
  } = React.useContext(SiteContext);

  const currencyRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = React.useState<boolean>(false);

  useClickOut({ ref: currencyRef, callback: () => setOpen(false) });

  const handleSetCurrency = (c: CurrencySymbol) => {
    dispatch(setCurrency(c));
    setOpen(false);
  };

  if (!usdExchangeRate) {
    return null;
  }

  return (
    <div ref={currencyRef} css={styles.root}>
      <Actionable
        role="select"
        ariaLabel={currency.toUpperCase()}
        css={styles.button}
        onClick={() => setOpen(!isOpen)}
        analytics={{
          category: EventCategory.Site_Header,
          action: `${isOpen ? "Close" : "Open"} - Currency selector`,
          label: currency.toUpperCase(),
        }}
      >
        {currency.toUpperCase()}

        <Arrow
          direction={isOpen ? "up" : "down"}
          size={{ width: 10, height: 7 }}
          marginLeft={6}
        />
      </Actionable>

      <div css={styles.dropdownContainer}>
        <AnimateHeight duration={200} height={isOpen ? "auto" : 0}>
          <Card pop={true}>
            <div css={[styles.row, styles.header]}>
              <span>Currency</span>
              <span>Symbol</span>
            </div>
            {SUPPORTED_CURRENCIES.map((c) => (
              <Actionable
                key={c}
                role="option"
                ariaLabel={c}
                css={[
                  styles.row,
                  styles.item,
                  c === currency && styles.itemActive,
                ]}
                onClick={() => handleSetCurrency(c)}
                analytics={{
                  category: EventCategory.Site_Header,
                  action: "Select - currency",
                  label: CURRENCY_NAME[c],
                }}
              >
                <span>{CURRENCY_NAME[c]}</span>
                <span css={styles.symbol}>{c.toUpperCase()}</span>
              </Actionable>
            ))}
            <div css={{ height: grid(1.5) }} />
          </Card>
        </AnimateHeight>
      </div>
    </div>
  );
};

export default CurrencySelector;
