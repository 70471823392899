import { CSSObject } from "@emotion/react";
import { Border } from "../design-tokens/borders";
import { BoxShadow } from "../design-tokens/box-shadow";
import { Colors } from "../design-tokens/colors";

const root: CSSObject = {
  position: "relative",
  display: "block",
  backgroundColor: Colors.White,
  borderRadius: Border.Radius,
  boxShadow: BoxShadow.Default,
};

const rootPop: CSSObject = {
  boxShadow: BoxShadow.Pop,
};

const rootHover: CSSObject = {
  transition: "all 0.2s ease",

  ":hover": {
    boxShadow: BoxShadow.Hover,
    transform: "translateY(-3px)",
  },
};

const styles = {
  root,
  rootPop,
  rootHover,
};

export default styles;
