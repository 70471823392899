import * as React from "react";
import Image from "../../ui-library/image/image";
import Container from "../../ui-library/container/container";
import Link from "../../ui-library/link/link";
import { EventCategory } from "../../../utils/analytics";
import styles from "./coingecko-credit.styles";
import WebsocketIcon from "./websocket-icon.svg";

const CoingeckoCredit: React.FC = () => {
  return (
    <Container css={styles.root}>
      <Link
        to="https://www.coingecko.com/en/api"
        css={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        analytics={{
          category: EventCategory.Site_Footer,
          action: "Click - Coingecko credit",
          label: "CoinGecko",
        }}
      >
        <Image src={WebsocketIcon} alt="Websocket icon" />
        CoinGecko
      </Link>
    </Container>
  );
};

export default CoingeckoCredit;
