import * as React from "react";
import "intersection-observer";
import { Global } from "@emotion/react";
import CookiePolicy from "../shared/cookie-policy/cookie-policy";
import { globalStyle, styles } from "./layout.styles";

import Header from "./header/header";
import { NavTab } from "./navigation/navigation";
import Footer from "./footer/footer";
import CoingeckoCredit from "./coingecko-credit/coingecko-credit";

interface LayoutProps {
  activeTab?: NavTab;
}

const Layout: React.FC<LayoutProps> = ({ activeTab, children }) => {
  return (
    <>
      <Global styles={globalStyle} />

      <Header activeTab={activeTab} />

      <main css={styles.content}>{children}</main>

      <CookiePolicy />

      <CoingeckoCredit />

      <Footer />
    </>
  );
};

export default Layout;
