import * as React from "react";
import { EventCategory } from "../../../utils/analytics";
import Link from "../../ui-library/link/link";
import styles from "./navigation.styles";

export enum NavTab {
  Coins = "coins",
  Exchanges = "exchanges",
  Categories = "categories",
  Calculator = "calculators",
}

interface NavigationProps {
  activeTab?: NavTab;
  device: "desktop" | "mobile";
}

const Navigation: React.FC<NavigationProps> = ({ activeTab, device }) => {
  return (
    <nav
      css={[
        styles.root,
        device === "desktop" && styles.hiddenMobile,
        device === "mobile" && styles.hiddenDesktop,
      ]}
    >
      <Link
        to="/coins/"
        css={[styles.item, activeTab === NavTab.Coins && styles.itemActive]}
        analytics={{
          category: EventCategory.Site_Navigate,
          action: "Click - Coin +",
          label: "Coin +",
        }}
      >
        Coins +
      </Link>
      <Link
        to="/exchanges/"
        css={[styles.item, activeTab === NavTab.Exchanges && styles.itemActive]}
        analytics={{
          category: EventCategory.Site_Navigate,
          action: "Click - Exchanges",
          label: "Exchanges",
        }}
      >
        Exchanges
      </Link>
      <Link
        to="/categories/"
        css={[
          styles.item,
          activeTab === NavTab.Categories && styles.itemActive,
        ]}
        analytics={{
          category: EventCategory.Site_Navigate,
          action: "Click - Categories",
          label: "Categories",
        }}
      >
        Categories
      </Link>
      {/* <Link
        to="/"
        css={[
          styles.item,
          activeTab === NavTab.Calculator && styles.itemActive,
        ]}
        analytics={{
          category: EventCategory.Site_Navigate,
          action: "Click - Calculator",
          label: "Calculator",
        }}
      >
        Calculator
      </Link>
      <Link
        to="/"
        css={[
          styles.item,
          activeTab === NavTab.Calculator && styles.itemActive,
        ]}
        analytics={{
          category: EventCategory.Site_Navigate,
          action: "Click - Resources",
          label: "Resources",
        }}
      >
        Resources
      </Link> */}
    </nav>
  );
};

export default Navigation;
