import * as React from "react";
import { EventAttributes, EventCategory } from "../../../utils/analytics";
import Actionable from "../actionable/actionable";
import Link from "../link/link";
import styles from "./pill-button.styles";

interface PillButtonProps {
  to?: string;
  onClick?: () => void;
  className?: string;
  name: string;
  size?: "small" | "large";
  color?: "white" | "blue";
  outline?: boolean;
  target?: string;
  eventCategory: EventCategory;
}

const PillButton: React.FC<PillButtonProps> = ({
  to,
  name,
  size = "large",
  color = "white",
  outline,
  target,
  children,
  eventCategory,
  ...props
}) => {
  const analytics: EventAttributes = {
    category: eventCategory,
    action: `Click - ${name}`,
    label: name,
  };

  const content = (
    <span css={[styles.label, size === "large" && { paddingBottom: "2px" }]}>
      {children}
    </span>
  );

  const style = [
    styles.root,
    size === "small" && styles.small,
    color === "white" ? styles.white : styles.blue,
    outline && styles.border,
  ];

  return to ? (
    <Link to={to} css={style} target={target} {...props} analytics={analytics}>
      {content}
    </Link>
  ) : (
    <Actionable
      role="button"
      ariaLabel={name}
      css={style}
      {...props}
      analytics={analytics}
    >
      {content}
    </Actionable>
  );
};

export default PillButton;
