import * as React from "react";
import { EventAttributes } from "../../../utils/analytics";
import Link from "../link/link";
import styles from "./card.styles";

interface CardProps {
  id?: string;
  pop?: boolean;
  className?: string;
}

export const Card: React.FC<CardProps> = ({ id, pop, className, children }) => {
  return (
    <div
      id={id}
      css={[styles.root, pop && styles.rootPop]}
      className={className}
    >
      {children}
    </div>
  );
};

interface LinkCardProps {
  href: string;
  isExternal?: boolean;
  className?: string;
  analytics: EventAttributes;
}

export const LinkCard: React.FC<LinkCardProps> = ({
  href,
  isExternal,
  className,
  children,
  analytics,
}) => {
  const attributes = {
    css: [styles.root, styles.rootHover],
    className,
    analytics,
  };

  return isExternal ? (
    <a href={href} target="_blank" rel="noreferrer" {...attributes}>
      {children}
    </a>
  ) : (
    <Link to={href} {...attributes}>
      {children}
    </Link>
  );
};
