import { CSSObject } from "@emotion/react";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  textAlign: "right",
};

const link: CSSObject = {
  display: "inline-flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.DarkGrey,
  marginBottom: grid(2),

  [Device.DesktopTablet]: {
    marginBottom: grid(2.5),
  },

  img: {
    marginRight: grid(0.5),
  },
};

const styles = {
  root,
  link,
};

export default styles;
