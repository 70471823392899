import * as React from "react";
import { CSSObject } from "@emotion/react";
import { Colors } from "../design-tokens/colors";

export interface ArrowSize {
  width: number;
  height: number;
}

type ArrowDirection = "up" | "down";

interface ArrowProps {
  direction: ArrowDirection;
  size?: ArrowSize;
  color?: string;
  marginRight?: number;
  marginLeft?: number;
}

const getArrowStyles = ({
  size = { width: 9, height: 8 },
  direction,
  color = Colors.White,
}: ArrowProps) => {
  const { width, height } = size;

  const style: CSSObject = {
    display: "inline-block",
    borderLeft: `${width / 2}px solid transparent`,
    borderRight: `${width / 2}px solid transparent`,
    borderBottom: `${height}px solid ${color}`,
    transition: "all 0.2s ease",
  };

  if (direction === "down") {
    style.transform = "rotate(180deg)";
  }

  return style;
};

const Arrow: React.FC<ArrowProps> = ({
  direction,
  size,
  color,
  marginRight,
  marginLeft,
}) => {
  const styles = getArrowStyles({ size, direction, color });

  return (
    <span css={{ display: "inline-flex", marginRight, marginLeft }}>
      <span css={styles} />
    </span>
  );
};

export default Arrow;
